<template>
  <div class="card p-10">
    <div>
      <h2 class="text-blue fw-bolder">Editar Equipo</h2>
    </div>
    <div class="mt-10">
      <span class="text-blue fw-bolder">Dispositivo</span>
      <h2 class="text-gray fw-bolder">
        Sensor <span>{{ nombre }}</span>
      </h2>
    </div>
    <div class="row my-5 pb-5 border-bottom border-3">
      <div class="col-md-6 col-sm-12 my-5">
        <label class="text-secondary" for="">Nombre</label>
        <input
          type="text"
          class="rounded-0 form-control bg-gray-input border-0 text-gray"
          placeholder="Ingrese el nombre del equipo"
          v-model="nombre"
        />
      </div>
      <div class="col-md-6 col-sm-12 my-5">
        <label class="text-secondary" for="">Serial</label>
        <input
          type="text"
          class="rounded-0 form-control bg-gray-input border-0 text-gray"
          placeholder="Ingrese el serial del equipo"
          v-model="serial"
        />
      </div>
      <div class="col-md-6 col-sm-12 my-5">
        <label class="text-secondary" for="">Ubicación</label>
        <select
          class="form-select bg-gray-input text-gray border-0 rounded-0"
          aria-label="Default select example"
          v-model="ubicacionId"
        >
          <option disabled value="">Seleccione una ubicación</option>
          <option
            v-for="(ubicacion, i) in ubicaciones"
            :key="i"
            :value="ubicacion._id"
          >
            {{ ubicacion.nombre }}
          </option>
        </select>
      </div>
      <div class="col-md-6 col-sm-12 my-5">
        <label class="text-secondary" for="">Area</label>
        <select
          class="form-select bg-gray-input text-gray border-0 rounded-0"
          aria-label="Default select example"
          v-model="areaId"
        >
          <option disabled value="">Seleccione un área</option>
          <option v-for="(area, i) in areas" :key="i" :value="area._id">
            {{ area.nombre }}
          </option>
        </select>
      </div>
    </div>
    <div>
      <div class="d-flex align-items-center">
        <i class="bi bi-gear me-2 text-blue fs-3"></i>
        <h4 class="text-blue m-0">Configuración de equipo</h4>
      </div>
      <div class="col-md-6 col-sm-12 my-5">
        <label class="text-secondary" for="">Modo</label>
        <select
          class="form-select bg-gray-input text-gray border-0 rounded-0"
          aria-label="Default select example"
        >
          <option selected>Seleccione un modo de configuración</option>
          <option></option>
        </select>
      </div>
    </div>
    <div class="d-flex justify-content-center my-5">
      <div class="text-center d-block col-lg-3 col-sm-12">
        <div class="w-100">
          <button
            type="submit"
            class="btn bg-blue rounded-pill text-white w-100"
          >
            Agregar
          </button>
        </div>
        <div class="w-100">
          <button
            type="button"
            class="btn text-blue w-100"
            @click="$router.back()"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import sedes from "@/services/sedes.service";
import { useRoute } from "vue-router";
import equipos from "@/services/equipos.service";

export default defineComponent({
  name: "overview",
  components: {},
  setup() {
    const ubicaciones = ref([]);
    const ubicacionId = ref("");
    const areas = ref([]);
    const route = useRoute();
    const informacionEquipo = ref({});
    const nombre = ref("");
    const serial = ref("");
    const areaId = ref("");
    const traerInformacionDelEquipo = () => {
      equipos.traerDispositivoPorId(route.params.id).then((res) => {
        console.log("respuesta dispositivo", res.data);
        informacionEquipo.value = res.data;
        nombre.value = res.data.nombre;
        serial.value = res.data.serial;
        ubicacionId.value = res.data.sedeId;
        areaId.value = res.data.areaId;
      });
    };
    const traerUbicaciones = () => {
      sedes.listarSedes().then((res) => {
        console.log(res);
        ubicaciones.value = res.data;
      });
    };
    const traerAreas = () => {
      sedes.listarAreas(ubicacionId.value).then((res) => {
        console.log("respuesta de listar areas", res.data.areas);
        areas.value = res.data.areas;
      });
    };
    watch(
      () => ubicacionId.value,
      () => {
        traerAreas(ubicacionId.value);
      }
    );
    onMounted(() => {
      setCurrentPageBreadcrumbs("Crear equipo", ["Pages", "Equipos"]);
      traerUbicaciones();
      traerInformacionDelEquipo();
    });
    return {
      ubicaciones,
      areas,
      ubicacionId,
      informacionEquipo,
      nombre,
      serial,
      areaId,
    };
  },
});
</script>
<style scoped>
.border-blue {
  border-color: #27aae1 !important;
}
.text-gray {
  color: #999999;
}
.text-blue {
  color: #27aae1;
}
.bg-blue {
  background-color: #27aae1;
}
.bg-gray-input {
  background-color: #f4f8fb;
}
.listado-info {
  padding: 0;
  list-style: none;
}
.listado-info li label {
  font-size: 1.125rem;
}
.listado-info li p {
  font-size: 1.125rem;
  color: #999999;
}
</style>
